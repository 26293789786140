import {
  configSymbol,
  type GenericResolvedConfig,
  type GenericConfigOrFn,
  type GenericProps,
  type Type,
} from './types.js'

import forwardProps from './forwardProps.js'
import { twMerge } from 'tailwind-merge'


export default function buildComponent(configs: GenericConfigOrFn[], baseTagName?: Type) {
  const Component = memo(function <T extends Dx.Tag.Name>(externalProps: GenericProps<T>) {
    const style = {}
    const attributes = {}
    const props = {}
    const className = []
    const omit = []

    let tagName = baseTagName

    for (const config of configs) {
      const {
        attributes: attributes_,
        omit: omit_ = Array._,
        className: className_,
        style: style_,
        tagName: tagName_,
        ...configProps
      } = resolve(config, externalProps)

      Object.assign(props, configProps)
      Object.assign(style, style_)
      Object.assign(attributes, normalizeAttributes(attributes_))
      className.push(className_)
      omit.push(...omit_)
      tagName ??= tagName_
    }

    typeof tagName === 'string' ?
      Object.assign(props, forwardProps(externalProps, new Set([...omit]))) :
      Object.assign(props, Object.omit(externalProps, omit))

    Object.assign(style, externalProps.style)
    className.push(externalProps.className)

    if (!__production__) {
      Object.assign(attributes, {
        'data-dx-name': (Component as any).displayName,
        'data-dx-path': (Component as any).filePath,
      })
    }

    return createElement(tagName ?? 'div', {
      ...props,
      className: twMerge(classNames(className).replace(/[\n ]+/g, ' ').trim()),
      style,
      ...attributes,
    })
  })
      
  Object.defineProperties(Component, {
    [configSymbol]: {
      get: () => configs,
    },
  })

  return Component
}

function normalizeAttributes(attributes: AnyRecord<string> = Object._) {
  return Object.filter(attributes, value => value != null)
}


function resolve<T extends Dx.Tag.Name>(config: GenericConfigOrFn | undefined, props: GenericProps<T>): GenericResolvedConfig {
  const resolved = invoke(config, props) ?? {}
  return Object.isObject(resolved) ? resolved : { className: resolved }
}