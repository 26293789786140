import type { PageContext } from 'vike/types'
import type { NormalizedMetadata } from './types.js'
import { getSetting } from '../getSetting.js'
import defaults from './defaultMetadata.js'
import { buildImageUrl } from '@/common/Image'


const siteName = 'DxCheck'

export default function getMetadata(context: PageContext): NormalizedMetadata {
  const base = getSetting('metadata', context) ?? {}
  const title = Array.cast(coalesce(base.title, defaults.title)).compact()

  return {
    title: (title.some(t => t.includes(siteName)) ? title : [...title, siteName]).compact().join(' - '),
    description: coalesce(base.description, defaults.description),
    snippetTitle: coalesce(base.snippetTitle, (title.length > 1 ? title.pull(siteName) : title).compact().join('-')),
    snippet: coalesce(base.snippet, defaults.snippet),
    lang: coalesce(base.lang, defaults.lang),
    twitterCard: coalesce(base.twitterCard, (base.image ? 'summary_large_image' as const : 'summary' as const)),
    url: dx.url.parse(coalesce(base.url, context.urlOriginal))?.href,
    image: base.image ?
      buildImageUrl({ width: 1200, height: 630, src: base.image }) :
      undefined,
  }
}
