import traceOps from '../ops.js'


export type OperationOptions = {
  id: string
  name: string
}

export type ExecuteAttributes = {
  operation: OperationOptions,
  model: {
    name: string
    service: string
    identifier: string
  },
  stream: boolean
  temperature: number
  usage: ExecutionUsage
}

export type ExecuteUpdateAttributes = {
  usage: ExecutionUsage
}

type ExecutionUsage = {
  prompt: number
  completion: number
  total: number
}

export default traceOps({
  pipeline({ name }: { name: string }) {
    return {
      op: 'ai.pipeline',
      name,
      attributes: {
        'ai.pipeline.name': name,
      },
    }
  },

  execute({ operation, model, stream, temperature, usage }: ExecuteAttributes) {
    return {
      op: `ai.chat_completions.create.${model.service}`,
      name: model.identifier,
      attributes: {
        'ai.pipeline.name': operation.name,
        'ai.model_id': model.identifier,
        'ai.streaming': stream,
        'ai.temperature': temperature,
        'ai.prompt_tokens.used': usage.prompt,
      },
      update: ({ usage: usage_ }: ExecuteUpdateAttributes) => ({
        'ai.completion_tokens.used': usage_.completion,
        'ai.total_tokens.used': usage_.total,
      }),
    }
  },
})
