import traceOps from '../ops.js'

export default traceOps({
  publish({ task, messageId, size }: { task: Dx.Task, messageId: string, size: number }) {
    return {
      op: 'queue.publish',
      name: 'publish tasks',
      attributes: {
        'messaging.destination.name': 'tasks',
        'messaging.destination.template': task.type,
        'messaging.message.body.size': size,
        'messaging.message.id': messageId,
        'messaging.operation.name': 'publish',
        'messaging.operation.type': 'publish',
        'messaging.system': 'cloudflare',
      },
    }
  },

  process({ task, attempts }: { task: Dx.Task.Published, attempts: number }) {
    return {
      op: 'queue.process',
      name: 'consume tasks',
      attributes: {
        'messaging.destination.name': 'tasks',
        'messaging.destination.template': task.type,
        'messaging.message.body.size': task.trace.size,
        'messaging.message.id': task.trace.messageId,
        'messaging.operation.name': 'consume',
        'messaging.operation.type': 'process',
        'messaging.message.receive.latency': Date.now() - task.trace.time,
        'messaging.message.retry.count': attempts ?? 0,
        'messaging.system': 'cloudflare',
      },
    }
  },
})
