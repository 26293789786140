import getGlobal from '#/getGlobal'
import type { PageContext, PageContextClient } from 'vike/types'


const globals = getGlobal('pageContext', () => ({
  Context: createContext({} as Simplify<PageContext & PageContextClient>),
}))
  
type RenderContextProviderProps = {
  context: PageContext & PageContextClient
  children: React.ReactNode
}

export function RenderContextProvider(props: RenderContextProviderProps) {
  const {
    context,
    children,
  } = props

  const { Context } = globals

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  )
}

export default function useRenderContext() {
  const { Context } = globals
  return useContext(Context)
}