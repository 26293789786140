import { isEqualWith } from 'lodash-es'
import { invoke } from '#/function/index'

export default function useMemoObject<T>(object: T | (() => T), deps?: React.DependencyList): T {
  const prevObject = useRef<T>(null)

  useMemo(() => {
    const nextObject = invoke(object)

    if (!isEqual(prevObject.current, nextObject)) {
      prevObject.current = nextObject
    }
  }, deps as any) // eslint-disable-line react-hooks/exhaustive-deps

  return prevObject.current!
}

export function isEqual(a: any, b: any) {
  return isEqualWith(a, b, (_v, _v2, key) => {
    if (shouldFilterKey(key)) {
      return true
    }
  })
}

export function shouldFilterKey(key: any) {
  return key === '_owner'
}
