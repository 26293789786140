import './types.js'
import type ReactDOM from 'react-dom/client'
import type { OnRenderClientSync, PageContextClient } from 'vike/types'
import buildPage from './components/buildPage.js'
import * as strategies from './strategies/index.js'


let root: ReactDOM.Root

export const onRenderClient: OnRenderClientSync = context => {
  const container = getContainer()

  context.renderType = determineRenderType(container, context)
  context.page = buildPage(context)
  context.root = root = strategies[context.renderType]({
    root,
    container,
    context,
  })

  context.config.onAfterRenderClient?.(context)
}

function getContainer() {
  const container = document.getElementById('app')

  if (!container) {
    throw new Error(`Root element '#app' not found`)
  }

  return container
}

function determineRenderType(container: HTMLElement, context: PageContextClient) {
  if (container.innerHTML !== '' && context.isHydration) {
    return 'hydration'
  }

  if (root) {
    return 'navigation'
  }

  return 'ssr'
}