import React from 'react'
import type { PageContext } from 'vike/types'
import { RenderContextProvider } from '#/hooks/useRenderContext'
import { PassThrough } from './PassThrough.js'

export default function buildPage(context: PageContext) {
  const Layout = context.config.Layout ?? PassThrough
  const Wrapper = context.config.Wrapper ?? PassThrough
  const Page = context.Page ?? PassThrough

  return (
    <RenderContextProvider context={context as any}>
      <Wrapper>
        <Layout>
          <Page />
        </Layout>
      </Wrapper>
    </RenderContextProvider>
  )
}