import type { Scope } from '@sentry/types'
import { getDefaultCurrentScope, getDefaultIsolationScope } from '@sentry/core'
import type { Trace } from './types.js'

type Callback<T> = (scope: Scope) => T

export const asyncContextStrategy = {
  getCurrentScope: () => dx.trace.scope,
  getIsolationScope: () => dx.trace.isolationScope,

  withScope: <T>(callback: Callback<T>) =>
    withScopes(dx.trace.scope.clone(), null, callback),

  withSetScope: <T>(scope: Scope, callback: Callback<T>) =>
    withScopes(scope, dx.trace.isolationScope.clone(), callback),

  withIsolationScope: <T>(callback: Callback<T>) =>
    withScopes(null, dx.trace.isolationScope.clone(), callback),

  withSetIsolationScope: <T>(isolationScope: Scope, callback: Callback<T>) =>
    withScopes(null, isolationScope, callback),
}


export function defaultScopes(): Trace.Scopes {
  return {
    scope: getDefaultCurrentScope(),
    isolationScope: getDefaultIsolationScope(),
  }
}

export function withScopes<T>(scope: Nullable<Scope>, isolationScope: Nullable<Scope>, callback: Callback<T>) {
  const scopes = {
    scope: scope ?? dx.trace.scope,
    isolationScope: isolationScope ?? dx.trace.isolationScope,
  }

  const next = scope ? scopes.scope : scopes.isolationScope
  return dx.trace.withScopes(scopes, () => callback(next))
}