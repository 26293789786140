 import traceOps from '../ops.js'

export default traceOps({
  get({ key }: { key: string }) {
    return {
      op: 'cache.get',
      name: key,
      attributes: {
        'cache.key': [key],
      },
    }
  },

  put({ key, size, ttl }: { key: string, size: Nullable<number>, ttl: Nullable<number> }) {
    return {
      op: 'cache.put',
      name: key,
      attributes: {
        'cache.item_size': size ?? undefined,
        'cache.key': [key],
        'cache.ttl': ttl ?? undefined,
      },
    }
  },

  flush() {
    return {
      op: 'cache.flush',
      name: 'cache.flush',
    }
  },

  remove({ keys }: { keys: string[] }) {
    return {
      op: 'cache.remove',
      name: keys.sort().join(', '),
      attributes: {
        'cache.key': keys,
      },
    }
  },
})
