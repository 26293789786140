type ImageProps = Dx.Props.Combine<'img', CommonImageOptions & {
  alt: string
  aspectRatio?: string
  className?: string
  sizes?: Sizes | 'full' // min-width: Npx
}>


type Sizes = Partial<Record<keyof typeof breakpoints | 'default', string | number>>

type BuildImageUrlOptions = Merge<CommonImageOptions, {
  height?: number
  width?: number
}>

type CommonImageOptions = {
  blur?: number // 1-250
  brightness?: number // 1 no change
  contrast?: number // 1 no change
  gamma?: number // 1 no change
  rotate?: number // 90, 180, 270
  sharpen?: number // 0-10
  fit?: 'none' | 'contain' | 'cover' | 'crop' | 'pad' | 'scale-down'
  gravity?: 'auto' | 'left' | 'right' | 'top' | 'bottom' | `${number}x${number}`
  quality?: number
  src: string
}

const breakpoints = {
  '2xs': 375,
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
  '3xl': 1920,
}

const fullSizes: Sizes = {
  '2xs': 480,
  xs: 640,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1536,
  '2xl': 1920,
}

export default function Image(props: ImageProps) {
  const {
    aspectRatio,
    sizes: sizes__,
    style,

    fit,
    gravity,
    quality,
    src,
    blur,
    brightness,
    contrast,
    gamma,
    rotate,
    sharpen,

    ...rest
  } = props

  const sizes_ = useMemoObject(sizes__ === 'full' ? fullSizes : sizes__)
  const options = useMemoObject({
    fit,
    gravity,
    quality,
    src,
    blur,
    brightness,
    contrast,
    gamma,
    rotate,
    sharpen,
  })


  const srcSet = useMemo(() =>
    Object
      .values(breakpoints)
      .map(width => [
        buildImageUrl({
          ...options,
          width,
        }),
        `${width}w`,
      ].join(' '))
      .join(', ')
  , [options])

  const sizes = useMemo(() => {
    const entries = Object
      .typedEntries(Object.omit(sizes_ ?? {}, ['default']))

    const descriptors = entries.map(([name, width], index) => {
      const min = `(min-width: ${breakpoints[name]}px)`
      const next = entries[index + 1]?.[0]
      const query = next ?
        `(${min} and (max-width: ${breakpoints[next] - 1}))` :
        min

      return `${query} ${widthToSize(width)}`
    })

    return [
      ...descriptors,
      widthToSize(sizes_?.default ?? '100vw'),
    ].join(', ')
  }, [sizes_])

  const source = useMemo(() =>
    buildImageUrl({
      ...options,
      width: breakpoints.sm,
    })
  , [options])

  return (
    <img
      {...rest}
      crossOrigin='anonymous'
      suppressHydrationWarning
      sizes={sizes}
      src={source}
      srcSet={srcSet}
      style={{ aspectRatio, ...style }} />
  )
}





const rotations = new Set([90, 180, 270])

export function buildImageUrl(options: BuildImageUrlOptions) {
  const {
    width,
    fit = 'cover',
    gravity = fit === 'cover' || fit === 'crop' ? 'auto' : undefined,
    height,
    quality,
    src,
    blur,
    brightness,
    contrast,
    gamma,
    rotate,
    sharpen,
  } = options as Widen<BuildImageUrlOptions>

  const transformations = [
    width && `w=${width}`,
    height && `h=${height}`,
    fit && fit !== 'none' && `fit=${fit}`,
    gravity && `g=${gravity}`,
    quality && `q=${quality}`,
    sharpen && `sharpen=${Math.clamp(sharpen, 1, 10)}`,
    gamma != null && `gamma=${Math.clamp(gamma, 0, 5)}`,
    contrast != null && `contrast=${Math.clamp(contrast, 0, 5)}`,
    brightness != null && `brightness=${Math.clamp(brightness, 0, 5)}`,
    blur && `blur=${Math.clamp(blur, 1, 250)}`,
    rotate && rotations.has(rotate) && `rotate=${rotate}`,
    'metadata=none',
  ].compact().join(',')

  if (/^https?:/.test(src)) {
    return [
      'https://dxcheck.com/cdn-cgi/image',
      transformations,
      src,
    ].join('/')
  }

  return [
    'https://dxcheck.com/cdn-cgi/imagedelivery/Na8YdnKQygzC2nV9eFjNMQ',
    src,
    transformations,
  ].join('/')
}


function widthToSize(width: string | number) {
  return typeof width === 'number' ?
    `${width}px` :
    width
}