import traceOps from '../ops.js'

export default traceOps({
  query({ sql }: { sql: string }) {
    return {
      op: 'db',
      name: sql,
      attributes: {
        'db.name': dx.setting('database.name'),
        'db.operation': getSqlOperation(sql),
        'db.query.text': sql,
        'db.system': 'postgresql',
        'server.address': dx.setting('database.host'),
        'server.port': dx.setting('database.port'),
      },
    }
  },
})


const pattern = regex('ig')`
  \b               # Word boundary
  (?<operation>    # Group "operation"
    select |
    insert |
    update |
    delete |
    truncate
  )
  \b               # Word boundary
`

function getSqlOperation(sql: string) {
  return (sql.scan(pattern, 'operation').last() ?? 'unknown').toUpperCase()
}