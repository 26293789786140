export default function pageView(title: string, url: string) {
  if (window?.zaraz) {
    try {
      window.zaraz.track('Pageview', {
       page_location: url,
       page_title: title,
      })

    } catch (e) {
      dx.capture(e)
    }
  }
}