import type { ErrorInfo } from 'react'
import { captureException } from '@sentry/browser'

export default function reactErrorHandler(error: unknown, { componentStack }: ErrorInfo) {
  if (is.error(error) && componentStack) {
    const ebError = new Error(error.message)
    ebError.name = `ErrorBoundary ${error.name}`
    ebError.stack = componentStack
    setCause(error, ebError)
  }

  if (import.meta.env.DEV) {
    console.error(error)
  }

  captureException(error, {
    captureContext: {
      contexts: { react: { componentStack } },
    },
  })
}


function setCause(error: Error, cause: unknown) {
  const seen = new WeakSet<Error>()

  function recurse(error_: Error, cause_: unknown) {
    if (seen.has(error_)) {
      return
    }

    if (error.cause) {
      seen.add(error_)

      if (is.error(error.cause)) {
        return recurse(error.cause, cause_)
      }
    }

    error.cause = cause_
  }

  recurse(error, cause)
}