import type {
  Argument as ClassNames,
  Value as ClassValue,
  ArgumentArray as ClassArguments,
  ReadonlyArgumentArray as ReadonlyClassArguments,
} from 'classnames'


export type Tag = Dx.Tag.Name
export type Type = Tag | React.FunctionComponent<any>


export const configSymbol: unique symbol = Symbol('styled config')

// styled
export type Styled = InheritFn & TagFns

// styled()
export type InheritFn = {
  <
    P extends CustomProps = EmptyObject,
    SC extends StyledComponent<any, any> = GenericStyledComponent,
    T extends Tag = never,
  >(styledComponent: SC, config: Widen<GetConfig<SC>[number]> & { tagName?: T }):
    SC extends StyledComponent<infer P2, infer T2> ?
      StyledComponent<P2 & P, IfNever<T, T2, T>> :
      never

  <
    P extends CustomProps = EmptyObject,
    C extends React.FunctionComponent<any> = React.FunctionComponent<any>,
    T extends Tag = never,
  >(component: C, config: ConfigOrFn<P, T>):
    C extends React.FunctionComponent<infer P2> ?
      StyledComponent<P2 & P, C> :
      never
}

// styled.div()
export type TagFns = {
  [T in Tag]: <P extends CustomProps = EmptyObject>(config?: ConfigOrFn<P, T>) => StyledComponent<P, T>
}


// StyledComponent
export type StyledComponent<
  P extends CustomProps = EmptyObject,
  T extends Type = Tag,
> = ((props: Props<P, T> & { ref?: Dx.Ref.Prop<T> }) => React.ReactNode) & {
  [configSymbol]: GenericConfigOrFn[]
}

export type ResolvedConfig<P extends CustomProps = EmptyObject, T extends Type = Tag> = Merge<
  Props<Partial<P>, T>,
  {
    attributes?: DataAttributes & (T extends Tag ? Dx.Tag.Attrs<T> : Dx.Props.Of<T>)
    className?: ClassNames
    omit?: string[]
    style?: React.CSSProperties
  }
>

export type Config<P extends CustomProps = EmptyObject, T extends Type = Tag> = ResolvedConfig<P, T> | ClassNames
export type ConfigFn<P extends CustomProps = EmptyObject, T extends Type = Tag> = (props: Props<P, T>) => ResolvedConfig<P, T> | ClassNames
export type ConfigOrFn<P extends CustomProps = EmptyObject, T extends Type = Tag> = ResolvedConfig<P, T> | NonNullable<ClassValue> | ClassArguments | ReadonlyClassArguments | ConfigFn<P, T>


export type CustomProps = Record<string, any>
export type GenericResolvedConfig = ResolvedConfig<CustomProps, 'div'>
export type GenericConfigOrFn = ConfigOrFn<CustomProps, 'div'>
export type GenericProps<T extends Type = 'div'> = Props<CustomProps, T>
export type GenericRef = Dx.Ref.Prop<'div'>
export type GenericStyledComponent = StyledComponent<CustomProps>
export type GenericComponent = GenericStyledComponent | React.FunctionComponent<any>
export type GetConfig<SC> = SC extends StyledComponent<any, any> ? SC[typeof configSymbol] : never
export type Props<P extends CustomProps = EmptyObject, T extends Type = Tag> = Dx.Props.Combine<T, P>


type DataAttributes = { [K in `data-${string}`]: any }