let cache: Record<string, string> | null = null

type NavigationEntry = PerformanceEntryList & {
  serverTiming?: Array<{ name: string, description: string }>
}

export function serverTimings() {
  return cache ??= getServerTimings()
}

function getServerTimings() {
  if (import.meta.env.SSR || !window.performance?.getEntriesByType) {
    return {}
  }

  const entries = window.performance.getEntriesByType('navigation') as unknown as NavigationEntry[]
  const timings = entries.flatMap(e => e.serverTiming ?? [])
  return timings.toObject(t => [t.name, t.description])
}