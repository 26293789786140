import type { NormalizedMetadata } from './types.js'

export default function setMetadata(metadata: NormalizedMetadata) {
  const {
    description,
    image,
    lang,
    snippet,
    snippetTitle,
    twitterCard,
    url,
  } = metadata

  document.title = metadata.title
  document.documentElement.lang = lang
  setMeta('description', description)
  setMeta('og:title', snippetTitle)
  setMeta('og:description', snippet ?? description)
  setMeta('og:url', url)
  setMeta('og:image', image)
  setMeta('twitter:card', twitterCard)
}

function setMeta(name: string, content: Nullable<string>) {
  if (!content) {
    return findMeta(name)?.remove()
  }

  const node = findMeta(name) ?? createMeta(name)
  node.setAttribute('content', content)
}

function findMeta(name: string) {
  return document.querySelector<HTMLMetaElement>(`meta[name='${name}']`)
}

function createMeta(name: string) {
  const node = document.createElement('meta')
  node.name = name
  document.head.appendChild(node)
  return node
}
