import type { StrategyOptions } from '../types.js'
import getMetadata from '../metadata/getMetadata.js'
import setMetadata from '../metadata/setMetadata.js'
import pageView from '../analytics/pageView.js'

// Client: subsequent navigation
export default function strategyNavigation({ context, root }: StrategyOptions) {
  dx.caching.navigate()
  root.render(context.page)

  const metadata = getMetadata(context)
  pageView(metadata.title, metadata.url ?? context.urlParsed.href)
  setMetadata(metadata)

  return root
}