import type { StrategyOptions } from '../types.js'
import ReactDOM from 'react-dom/client'
import reactErrorHandler from 'env/client/sentry/reactErrorHandler'

// Server: SSR render
export default function strategySsr({ container, context }: StrategyOptions) {
  const root = ReactDOM.createRoot(container, {
    onCaughtError: reactErrorHandler,
    onUncaughtError: reactErrorHandler,
  })

  root.render(context.page)
  return root
}