import {
  continueTrace,
  getActiveSpan,
  getRootSpan,
  spanToBaggageHeader,
  spanToTraceHeader,
  startSpanManual,
  startSpan,
  suppressTracing,
  withActiveSpan,
} from '@sentry/core'

import type { Span as CoreSpan } from '@sentry/types'
import type { Trace } from './types.js'

import { defaultScopes } from './scope.js'
import * as op from './ops/index.js'


const base = {
  begin: <T>(options: Trace.Span.Options, callback: (span: Trace.Span, info: Trace.Info) => T) =>
    startSpan(options, span => callback(enhance(span), {
      time: Date.now(),
      ...dx.trace.info(span),
    })),
  manual: <T>(options: Trace.Span.Options, callback: (span: Trace.Span, info: Trace.Info) => T) =>
    startSpanManual(options, span => {
      (span as any)._isStandaloneSpan = true

      return callback(enhance(span), {
        time: Date.now(),
        ...dx.trace.info(span),
      })
    }),
  active: getActiveSpan,
  within: withActiveSpan,
  op,
  suppress: suppressTracing,
  root: () => {
    const active = getActiveSpan()

    if (active) {
      return getRootSpan(active)
    }
  },
  info: (span: CoreSpan) => ({
    trace: spanToTraceHeader(span),
    baggage: spanToBaggageHeader(span),
  }),
  headers: () => {
    const root = dx.trace.root()
    const headers = root ? dx.trace.info(root) : undefined

    return Object.compact({
      'sentry-trace': headers?.trace,
      baggage: headers?.baggage,
    })
  },
  continue: <V>(info: Partial<Omit<Trace.Info, 'time'>> | undefined, callback: () => V) =>
    info ?
      continueTrace({ sentryTrace: info.trace, baggage: info.baggage }, callback) :
      dx.trace.within(dx.trace.active() ?? null, callback),

  withScopes: <T>(scopes: Trace.Scopes, callback: () => T): T =>
    dx.set('trace', trace(scopes), callback),

}

export default function trace(scopes: Trace.Scopes = defaultScopes()) {
  const locks: Promise<boolean>[] = []

  function lock() {
    const future = Promise.future<boolean>()
    locks.push(future)
    return () => future.resolve(true)
  }

  async function wait() {
    await Promise.all(locks)
  }

  return {
    ...base,
    ...scopes,
    lock,
    wait,
  }
}


function enhance(span: CoreSpan) {
  return Object.assign(span, {
    ok: (message?: string) => span.setStatus({ code: 1, message }),
    error: (message: unknown) => span.setStatus({ code: 2, message: String(message) }),
    set: span.setAttributes.bind(span),
  }) as Trace.Span
}