import {
  configSymbol,
  type Styled,
  type Tag,
  type GenericConfigOrFn,
  type GenericComponent,
} from './types.js'

import buildComponent from './buildComponent.js'


const cache = new Map<Tag, any>()

const inheritFn = (Component: GenericComponent, config: GenericConfigOrFn) =>
  configSymbol in Component ?
    buildComponent([...Component[configSymbol], config]) :
    buildComponent([config], Component)


export default new Proxy<Styled>(inheritFn as any, {
  get: (_, tagName: Tag) => cache.ensure(tagName, () =>
    (config = Object._) => buildComponent([config], tagName)
  ),

  set() {
    return false
  },
})