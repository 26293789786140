import type { StrategyOptions } from '../types.js'
import ReactDOM from 'react-dom/client'
import { serverTimings } from '#/dom/serverTimings'
import reactErrorHandler from 'env/client/sentry/reactErrorHandler'
import getMetadata from '../metadata/getMetadata.js'
import pageView from '../analytics/pageView.js'
import trace from 'env/server/context/trace/index'

// Client: first render
export default function strategyHydration({ context, container }: StrategyOptions) {
  dx.set('trace', trace(), noop)

  window.__csrf__ = new Promise<string>((resolve, reject) => {
    fetch('/api/security/csrf', {
      method: 'POST',
      credentials: 'include',
      headers: Object.compact({
        'Content-Type': 'text/plain',
        ...dx.trace.headers(),
      }),
    })
    .then(r => r.text())
    .then(resolve)
    .catch(reject)
  })
  .catch(e => {
    console.error('CSRF', e)
    return ''
  })

  const metadata = getMetadata(context)
  pageView(metadata.title, metadata.url ?? context.urlParsed.href)

  const root = ReactDOM.hydrateRoot(container, context.page, {
    onCaughtError: reactErrorHandler,
    onUncaughtError: reactErrorHandler,
  })

  dx.caching.append({ header: serverTimings().dxcache })

  return root
}

