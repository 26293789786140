import type { Trace } from './types.js'

export default function traceOps<const T extends Record<string, Trace.Ops.Fn>>(ops: T): Trace.Ops.Definition<T> {
  return Object
  .typedEntries(ops)
  .toObject(([name, fn]) => {
    const run = (...args: any[]) => {
      const callback = args.pop()
      const { update, ...options } = fn(args[0] ?? {})

      return [
        options,
        (span: Trace.Span) =>
          callback(Object.assign(span, {
            update: (...updateOptions: any[]) => span.set(update?.(...updateOptions) ?? {}),
          })),
      ] as const
    }

    function wrappedFn(...args: any[]) {
      const [options, callback] = run(...args)
      return dx.trace.begin(options, callback)
    }

    wrappedFn.manual = (...args: any[]) => {
      const [options, callback] = run(...args)
      return dx.trace.manual(options, callback)
    }

    return [
      name,
      wrappedFn,
    ]
  }) as any
}
