import { getFetchUrl, getHeader, getSize } from '#/fetch'
import traceOps from '../ops.js'

// https://github.com/open-telemetry/semantic-conventions/blob/main/docs/attributes-registry/http.md
export default traceOps({
  inbound({ request, route }: { request: Request, route?: string }) {
    const url = new URL(request.url)
    const method = request.method.toUpperCase()

    return {
      op: 'http.server',
      name: `${method} ${url.pathname}`,
      attributes: {
        'client.address': getHeader(request, 'cf-connecting-ip'),
        'http.request.body.size': getSize(request) ?? undefined,
        'http.request.header.content-type': getHeader(request, 'content-type'),
        'http.request.header.sentry-trace': Boolean(getHeader(request, 'sentry-trace')),
        'http.request.method': method,
        'http.route': route,
        'server.address': dx.setting('app.domain'),
        'url.path': url.pathname,
        'url.query': url.search.slice(1),
        'url.scheme': url.protocol.replace(':', ''),
        'user_agent.original': getHeader(request, 'user-agent'),
      },
      update({ response }: { response: Response }) {
        return {
          'http.response.body.size': getSize(response) ?? undefined,
          'http.response.header.cache-control': getHeader(response, 'cache-control'),
          'http.response.header.content-type': getHeader(response, 'content-type'),
          'http.response.header.sentry-trace': Boolean(getHeader(response, 'sentry-trace')),
          'http.response.header.server-timing': Boolean(getHeader(response, 'server-timing')),
          'http.response.status_code': response.status,
        }
      },
    }
  },

  outbound(request: { url: RequestInfo | URL } & RequestInit) {
    let {
      url,
      method = 'GET',
    } = request

    const url_ = new URL(getFetchUrl(url))
    method = method.toUpperCase()

    return {
      op: 'http.client',
      name: `${method} ${url_.pathname}`,
      attributes: {
        'http.request.body.size': getSize(request) ?? undefined,
        'http.request.header.content-type': getHeader(request, 'content-type'),
        'http.request.header.sentry-trace': Boolean(getHeader(request, 'sentry-trace')),
        'http.request.method': method,
        'server.address': url_.hostname,
        'server.port': url_.port ?? undefined,
        'url.full': url_.toString(),
        'url.scheme': url_.protocol.replace(':', ''),
        'user_agent.original': getHeader(request, 'user-agent'),
      },
      update({ response }: { response: Response }) {
        return {
          'http.response.body.size': getSize(response) ?? undefined,
          'http.response.header.cache-control': getHeader(response, 'cache-control'),
          'http.response.header.content-type': getHeader(response, 'content-type'),
          'http.response.header.sentry-trace': Boolean(getHeader(response, 'sentry-trace')),
          'http.response.header.server-timing': Boolean(getHeader(response, 'server-timing')),
          'http.response.status_code': response.status,
        }
      },
    }
  },

  page({ url }: { url: string }) {
    return {
      op: 'pageload',
      name: url,
    }
  },

  api({ url }: { url: string }) {
    return {
      name: url,
    }
  },
})
